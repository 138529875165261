.p-archive {
  .bg-image {
    background-image: url($imageDir + "/archive/jumbotron.jpg");

    &-activity {
      background-image: url($imageDir + "/archive/jumbotron-activity.jpg");
    }
    &-blog {
      background-image: url($imageDir + "/archive/jumbotron-blog.jpg");
    }
  }
}