// stylelint-disable declaration-no-important

@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
  }
}

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-pos {
  &-center {
    background-position: center !important;
  }
  &-left {
    background-position: left !important;;
  }
  &-right {
    background-position: right !important;;
  }
  &-top {
    background-position: top !important;;
  }
  &-bottom {
    background-position: bottom !important;;
  }
}

.bg-size {
  &-cover {
    background-size: cover !important;;
  }
  &-contain {
    background-size: contain !important;;
  }
}

.bg-attach {
  &-fix {
    background-attachment: fixed !important;
  }
}
