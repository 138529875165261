.article-card-list {
  .article-card {
    display: flex;

    &:not(:last-child) {
      margin-bottom: .3rem;
    }
    &:hover {
      background-color: $gray-100;
    }

    &__img {
      min-width: 120px;
      max-width: 120px;
      background: {
        position: center !important;
        size: cover !important;
      }
    }
    &__excerpt {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      &__img {
        min-width: 200px;
        max-width: 200px;
      }
      &__excerpt {
        display: inherit;
      }
    }
  }
}