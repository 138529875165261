.wp-pagenavi {
  text-align: center;
  display: flex;
  justify-content: space-around;
  margin: {
    top: 1rem;
    bottom: 1rem;
  };

  .pages,
  .current,
  .extend,
  .first,
  .last,
  .page {
    @extend .btn;
    display: none;
    color: white;
  }

  a {
    @extend .btn;
    @extend .btn-primary;
    color: white;
  }

  @include media-breakpoint-up(sm) {
    justify-content: center;

    .current,
    .extend,
    .page {
      display: block;
    }
    .current {
      background-color: $btn-link-disabled-color;
      cursor: inherit !important;
    }
  }
}