.l-footer {
  .sitemap {
    list-style: none;
    margin-bottom: 2rem;
    padding: 0;

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: center;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      @include media-breakpoint-up(sm) {
        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 2rem;
        }
      }
    }
  }
}