.p-profile {
  .jumbotron {
    background: {
      image: url($imageDir + "/profile/jumbotron.jpg");
    }
  }
  .profile__content {
    background-color: rgba(250, 250, 250, .8);
    padding: 2rem;

    @include media-breakpoint-down(md) {
      margin-top: -8rem;
    }
    @include media-breakpoint-down(sm) {
      margin-top: -6rem;
      padding: 1rem;
    }
  }
}